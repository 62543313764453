<template>
	<GridOptions v-if="0" />
	<TheArtGrid feedName="staff-picks-random" />
</template>

<script>
import TheArtGrid from '@/components/TheArtGrid'
import GridOptions from '@/components/GridOptions'

export default {
	name: 'HomeExplore',
	components: { TheArtGrid, GridOptions },
	props: {},
	data() {
		return {}
	},
	computed: {},
	created() {},
	methods: {},
}
</script>

<style scoped lang="scss"></style>
